import type { Transacao } from '@/types/Transacao'

export const useLivroDiarioAuxiliarStore = defineStore('livroDiarioAuxiliarStore', {
  state: () => ({
    transacao: {} as Transacao,
    depositosPrevios: [],
    filtros: {
      ano: new Date().getFullYear(),
      mes: new Date().getMonth() + 1,
      tipo: 'executado',
      termoDeBusca: '',
      receitaDespesa: 'todos',
    },
    formNovaReceita: {
      data: '',
      descricao: '',
      valor: 0,
      servicos: [],
    },
    modalAdicionarReceita: {
      exibir: false,
      receitaSelecionada: '',
      quantidade: 1,
      valor: 0,
      listaDeServicos: [],
      editMode: false,
    },
    modalCadastrarReceitas: {
      exibir: false,
      receita: {
        nome: '',
        valor: 0,
      },
      titulo: 'Cadastrar nova Receita',
      inputValor: null,
    },
    modalImportarXml: {
      exibir: false,
    },
  }),
  actions: {
    async fetchTransacao ({ ano = null, mes = null, receitaDespesa = null }: { ano?: string | null; mes?: string | null; receitaDespesa?: 'receita' | 'despesa' | null } = {}) {
      this.transacao = (await useApiStore().request(this.getTransacaoUrl({ ano, mes, receitaDespesa }), {
        method: 'GET',
      })) as Transacao
    },
    getTransacaoUrl ({ ano = null, mes = null, receitaDespesa = null }: { ano?: string | null; mes?: string | null; receitaDespesa?: 'receita' | 'despesa' | null } = {}): string {
      let url
      if (ano && mes) {
        url = `/gerencia/livro-diario-auxiliar/transacoes/${ano}/${mes}/executado?`
      } else {
        url = `/gerencia/livro-diario-auxiliar/transacoes/${this.filtros.ano}/${this.filtros.mes}/${this.filtros.tipo}?`
      }
      if (this.filtros.termoDeBusca !== '') {
        url += `busca=${this.filtros.termoDeBusca}`
      }
      if (receitaDespesa) {
        url += `&receitaDespesa=${receitaDespesa}`
      } else if (this.filtros.receitaDespesa !== 'todos') {
        url += `&receitaDespesa=${this.filtros.receitaDespesa}`
      }
      return url
    },
    async setFiltros (filtros: { mes: number; ano: number; tipo: string }) {
      for (const key in filtros) {
        if (Object.hasOwn(filtros, key)) {
          this.filtros[key] = filtros[key]
        }
      }

      await this.fetchTransacao()
    },
    async fetchServicos () {
      const servicos = await useApiStore().request('/gerencia/servico')
      return servicos
    },
    async uploadXml (file: File) {
      try {
        const formData = new FormData()
        formData.append('arquivo', file)

        // Envie os dados para a API
        const response = await useApiStore().upload('/gerencia/livro-diario-auxiliar/importar-xml', formData)

        // Verifique a resposta, se necessário
        console.log('Resposta do servidor:', response)
      } catch (error) {
        console.error('Erro ao enviar arquivo:', error)
        throw error // Rejeitar o erro para tratamento no componente, se necessário
      }
    },
    salvarReceita () {
      if (this.modalCadastrarReceitas.receita.id) {
        this.editarReceita()
      } else {
        this.cadastrarReceita()
      }
    },
    async cadastrarReceita () {
      await useApiStore().request('/gerencia/servico', {
        method: 'POST',
        body: this.modalCadastrarReceitas.receita,
      })
    },
    async editarReceita () {
      await useApiStore().request(`/gerencia/servico/${this.modalCadastrarReceitas.receita.id}`, {
        method: 'PUT',
        body: this.modalCadastrarReceitas.receita,
      })
    },
    abrirReceitaParaEdicao (receita: {nome: string, valor: number}) {
      this.modalCadastrarReceitas.receita = receita
      this.modalCadastrarReceitas.inputValor = receita.valor * 100
      this.modalCadastrarReceitas.titulo = 'Editar Receita'
      this.modalCadastrarReceitas.exibir = true
    },
    editarReceitaDoLancamento (index: number) {
      this.modalAdicionarReceita.exibir = true
      this.modalAdicionarReceita.receitaSelecionada = this.formNovaReceita.servicos[index].id
      this.modalAdicionarReceita.quantidade = this.formNovaReceita.servicos[index].quantidade
      this.modalAdicionarReceita.valor = this.formNovaReceita.servicos[index].valor
      this.modalAdicionarReceita.editMode = true
    },
  },
  getters: {},
  persist: {
    paths: [],
  },
})
